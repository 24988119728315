
import type { PropType } from 'vue';
import { defineComponent, reactive } from 'vue';

import { TermsService } from '@hems/service';
import { TERMS_TYPE } from '@hems/util/src/constant';

import { InstallerJoinPopup, GeneralJoinPopup } from '@/components';

import type { EnvLocale } from 'hems';

import type { SocialAccountInfo } from 'hems/auth/account';
import type { EffectiveTermsInfo } from 'hems/terms';

export default defineComponent({
  name: 'JoinPopupContainer',
  components: {
    InstallerJoinPopup,
    GeneralJoinPopup,
  },
  props: {
    joinType: {
      type: String as PropType<'general' | 'installer'>,
      default: 'general',
    },
    social: Object as PropType<SocialAccountInfo>,
    locale: String as PropType<EnvLocale>,
  },
  emits: ['close'],
  async setup(props) {
    const termsService = new TermsService(window.axiosInstance.axios);
    const termsType = props.joinType === 'general' ? TERMS_TYPE.USER : TERMS_TYPE.INSTALLER;
    const state = reactive({
      termsList: [] as EffectiveTermsInfo[],
    });

    const getTermsList = async () => {
      try {
        state.termsList = await termsService.getTerms(termsType);
      } catch (e) {
        console.error(e);
      }
    };

    await getTermsList();

    return {
      state,
    };
  },
});
